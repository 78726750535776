// 成绩查询
import {
  getRequest,
  // postRequest,
  // putRequest,
  deleteRequest,
  patchRequest
} from "@/libs/axios";
// 分页查询
export const getCj = params => {
  return getRequest("/yethan/examBank/listPage", params);
};
// 删除
export function deleteCj(sid) {
  return deleteRequest('/yethan/examBank/' + sid);
}
// 成绩详情

export function getCjDetails(sid) {
  return getRequest('/yethan/examBank/' + sid);
}

// 测试权限
export function userStatus(cscx, sid) {
  return patchRequest(`/yethan/examBank/${cscx}/${sid}`);
}
